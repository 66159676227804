import React from "react";
import HeaderMenu from "../../Components/HeaderMenu";
import FooterWebsite from "../../Components/FooterWebsite";

export default function PrivacyPage() {
  window.scrollTo(0, 0);
  return (
    <div className='relative isolate bg-background'>
      <HeaderMenu />
      <div
        className='absolute left-0 top-0 -z-10 transform-gpu overflow-hidden blur-3xl sm:top-0'
        aria-hidden='true'
      >
        <div
          className='relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-primary-light to-primary opacity-30 sm:left-4 sm:w-[72.1875rem]'
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
        />
      </div>
      <div
        className='absolute left-0 bottom-0 -z-10 transform-gpu overflow-hidden blur-3xl sm:bottom-0'
        aria-hidden='true'
      >
        <div
          className='aspect-[1097/845] w-[68.5625rem] bg-gradient-to-r from-primary-light to-primary opacity-25'
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
        />
      </div>
      <main className='relative isolate overflow-hidden mx-10 px-8 py-24 sm:py-32 lg:overflow-visible '>
        <div className='text-center'>
          <h1 className='text-4xl pb-4 tracking-tight leading-10 font-extrabold text-gray-800 sm:text-5xl sm:leading-none md:text-6xl'>
            Privacy Policy
          </h1>
        </div>
        <p>last updated: July 19 2023</p>
        <ul className='divide-y divide-gray-800'>
          <li>
            <h2 className='text-2xl p-2'>
              The privacy of your personal information is important to Mental
              Health Connections. It is our priority to keep your information
              safe and secure.
            </h2>
            <p className='p-4'>
              Mental Health Connections Ltd. and its corporate affiliates
              ("MHC", "we", "us", "our") respect and uphold individual rights to
              privacy and the protection of personal information while balancing
              such rights with our need to process your personal information to
              manage our business and deliver our services to you. Based on
              applicable privacy and data protection principles common to
              Canada, we have developed this privacy policy (the "Policy") to
              explain our practices for processing your personal information.
            </p>
          </li>
          <li>
            <h2 className='text-2xl p-2'>
              Retention of Your Personal Information
            </h2>
            <p className='p-4'>
              We generally keep personal information for only as long as it is
              needed to accomplish the purposes for which it was collected, or
              as needed for authorized or legitimate purposes or as otherwise
              necessary to comply with applicable laws or protect our interests.
              As one purpose of the Service is to assist you with the management
              of your personal information, including the personal health
              information that you provide when using our Service, we will keep
              your personal information until you delete your Service account or
              let us know that you no longer require our assistance.
            </p>
          </li>
          <li>
            <h2 className='text-2xl p-2'>CASL Policy</h2>
            <p className='p-4'>
              We are committed to compliance with Canada's Anti-Spam Legislation
              ("CASL"). Any electronic communication we send to outside parties
              is protected by a range of business procedures, processes and
              policies to ensure that such communication is done in compliance
              with CASL. In our electronic communications with outside parties,
              we comply with the rules established by CASL and enforced by
              various Canadian authorities including the Canadian
              Radio-television and Telecommunications Commission. CASL
              regulates, and our policies generally apply to, each commercial
              electronic message (a "CEM") that we send. A CEM is an electronic
              message sent to an electronic address that, among its purposes,
              encourages participation in a commercial activity. We do not send
              CEMs without having obtained either your express consent
              (expressly acknowledged by you) or having your implied consent
              (sending CEMs specific to the Services and our relationship with
              you). We will not send you CEMs which are for any purpose other
              than those CEMs necessary and specific to the Services and our
              relationship with you.
            </p>
            <p className='p-4'>
              If you receive a CEM from us but believe that you should not have
              or no longer wish to receive CEMs, we will aim to respect your
              preferences in a timely manner once you update them through our
              unsubscribe mechanism. CASL requires us to process unsubscribe
              requests within 10 business days. If you have any questions or
              concerns about our unsubscribe options, you may contact us at the
              address indicated in Section 10 below.
            </p>
          </li>
        </ul>
      </main>
      <FooterWebsite />
    </div>
  );
}
