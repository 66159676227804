import React from "react";

import { EnvelopeIcon } from "@heroicons/react/24/outline";
import { Formik, Form, Field, ErrorMessage } from "formik";
import HeaderMenu from "../../Components/HeaderMenu";
import FooterWebsite from "../../Components/FooterWebsite";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../../firebase-config";
import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";

export default function ContactPage() {
  const [sentInvite, setSentInvite] = useState(false);

  const cancelButtonRef = useRef(null);

  return (
    <div className='relative bg-transparent'>
      <Transition.Root show={sentInvite} as={Fragment}>
        <Dialog
          as='div'
          className='relative z-10'
          initialFocus={cancelButtonRef}
          onClose={setSentInvite}
        >
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
          </Transition.Child>

          <div className='fixed inset-0 z-10 overflow-y-auto'>
            <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center lg:pl-60 sm:p-0'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                enterTo='opacity-100 translate-y-0 sm:scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              >
                <Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6'>
                  <div>
                    <div className='mt-3 text-center sm:mt-5'>
                      <Dialog.Title
                        as='h3'
                        className='text-base font-semibold leading-6 text-gray-900'
                      >
                        Thank you for your message! We will get back to you as
                        soon as possible.
                      </Dialog.Title>
                      <div className='mt-2'>
                        <button
                          type='button'
                          onClick={() => setSentInvite(false)}
                          ref={cancelButtonRef}
                          className='inline-flex col-span-2 w-full justify-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white hover:text-dark-text shadow-sm hover:bg-primary-light focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-light'
                        >
                          Confirm
                        </button>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <HeaderMenu />
      <div className='absolute inset-y-0 left-0 -z-10 w-full lg:w-1/2'>
        <div
          className='absolute left-0 top-0 -z-10 transform-gpu overflow-hidden blur-3xl sm:top-0'
          aria-hidden='true'
        >
          <div
            className='relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-primary-light to-primary opacity-30 sm:left-4 sm:w-[72.1875rem]'
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
        <div
          className='absolute left-0 bottom-0 -z-10 transform-gpu overflow-hidden blur-3xl sm:bottom-0'
          aria-hidden='true'
        >
          <div
            className='aspect-[1097/845] w-[68.5625rem] bg-gradient-to-r from-primary-light to-primary opacity-25'
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
      </div>
      <div className='mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2'>
        <div className='relative px-6 pb-20 pt-24 sm:pt-32 lg:static lg:px-8 lg:py-48'>
          <div className='mx-auto max-w-xl lg:mx-0 lg:max-w-lg'>
            <h2
              id='contactTitle'
              className='text-3xl font-bold tracking-tight text-dark-text'
            >
              Get in touch
            </h2>
            <p
              id='contactMessage'
              className='mt-6 text-lg leading-8 text-dark-text'
            >
              Please send us a message with your question, inquiry, or comment.
              We look forward to hearing from you and supporting your journey to
              enhance your Mental Health one connection at a time.
            </p>
            <dl className='mt-10 space-y-4 text-base leading-7 text-dark-text'>
              <div className='flex gap-x-4'>
                <dt className='flex-none'>
                  <span className='sr-only'>Email</span>
                  <EnvelopeIcon
                    className='h-7 w-6 text-dark-text'
                    aria-hidden='true'
                  />
                </dt>
                <dd>
                  <a
                    className='hover:text-dark-text'
                    href='mailto:info@mhconnections.ca'
                    id='contactEmail'
                  >
                    info@mhconnections.ca
                  </a>
                </dd>
              </div>
            </dl>
          </div>
        </div>
        <div className='bg-transparent'>
          <Formik
            initialValues={{
              first_name: "",
              last_name: "",
              email: "",
              phone: "",
              message: "",
            }}
            validate={(values) => {
              const errors = {};
              if (!values.first_name) {
                errors.first_name = "Required";
              }
              if (!values.last_name) {
                errors.last_name = "Required";
              }
              if (!values.email) {
                errors.email = "Required";
              }
              if (!values.message) {
                errors.message = "Required";
              }
              return errors;
            }}
            onSubmit={async (values, { resetForm }) => {
              console.log(values);
              //alert(JSON.stringify(values, null, 2));
              const collectionRef = collection(db, "email");
              // email hard coded to my email for now
              var payload = {
                to: "info@mhconnections.ca",
                message: {
                  subject: "New Contact Us Email",
                  replyTo: values.email,
                  html: `<h1>New Contact Us Email</h1>
                  <p>First Name: ${values.first_name}</p>
                  <p>Last Name: ${values.last_name}</p>
                  <p>Email: ${values.email}</p>
                  <p>Phone: ${values.phone}</p>
                  <p>Message: ${values.message}</p>`,
                },
              };
              await addDoc(collectionRef, payload);
              //console.log("Document written with ID: ", docRef.id);
              payload = {
                to: values.email,
                template: {
                  name: "contact_us_reply_email",
                  data: {
                    person_name: values.first_name + " " + values.last_name,
                  },
                },
              };
              await addDoc(collectionRef, payload);

              setSentInvite(true);

              resetForm();
            }}
          >
            {({ handleSubmit, errors }) => (
              <Form
                className='px-6 pb-24 pt-20 sm:pb-32 lg:px-8 lg:py-48'
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    handleSubmit();
                  }
                }}
              >
                <div className='mx-auto max-w-xl lg:mr-0 lg:max-w-lg'>
                  <div className='grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2'>
                    <div>
                      <label
                        htmlFor='first-name'
                        className='block text-sm font-semibold leading-6 text-dark-text'
                      >
                        First name
                      </label>
                      <div className='mt-2.5'>
                        <Field
                          type='text'
                          name='first_name'
                          id='first_name'
                          autoComplete='given-name'
                          className='block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-dark-text focus:ring-2 focus:ring-inset hover:ring-primary focus:ring-primary sm:text-sm sm:leading-6'
                        />
                        <ErrorMessage
                          name='first_name'
                          component='div'
                          className='text-red-700 text-sm mt-1'
                        />
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor='last-name'
                        className='block text-sm font-semibold leading-6 text-dark-text'
                      >
                        Last name
                      </label>
                      <div className='mt-2.5'>
                        <Field
                          type='text'
                          name='last_name'
                          id='last_name'
                          autoComplete='family-name'
                          className='block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset hover:ring-primary focus:ring-primary sm:text-sm sm:leading-6'
                        />
                        <ErrorMessage
                          name='last_name'
                          component='div'
                          className='text-red-700 text-sm mt-1'
                        />
                      </div>
                    </div>
                    <div className='sm:col-span-2'>
                      <label
                        htmlFor='email'
                        className='block text-sm font-semibold leading-6 text-dark-text'
                      >
                        Email
                      </label>
                      <div className='mt-2.5'>
                        <Field
                          type='email'
                          name='email'
                          id='email'
                          autoComplete='email'
                          className='block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset hover:ring-primary focus:ring-primary sm:text-sm sm:leading-6'
                        />
                        <ErrorMessage
                          name='email'
                          component='div'
                          className='text-red-700 text-sm mt-1'
                        />
                      </div>
                    </div>
                    <div className='sm:col-span-2'>
                      <label
                        htmlFor='phone-number'
                        className='block text-sm font-semibold leading-6 text-dark-text'
                      >
                        Phone number
                      </label>
                      <div className='mt-2.5'>
                        <Field
                          type='phone'
                          name='phone'
                          id='phone'
                          autoComplete='tel'
                          className='block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset hover:ring-primary focus:ring-primary sm:text-sm sm:leading-6'
                        />
                      </div>
                    </div>
                    <div className='sm:col-span-2'>
                      <label
                        htmlFor='message'
                        className='block text-sm font-semibold leading-6 text-dark-text'
                      >
                        Message
                      </label>
                      <div className='mt-2.5'>
                        <Field
                          as='textarea'
                          name='message'
                          id='message'
                          rows={4}
                          className='block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset hover:ring-primary focus:ring-primary sm:text-sm sm:leading-6'
                          defaultValue={""}
                        />
                        <ErrorMessage
                          name='message'
                          component='div'
                          className='text-red-700 text-sm mt-1'
                        />
                      </div>
                    </div>
                  </div>
                  <div className='mt-8 flex justify-end'>
                    <button
                      type='submit'
                      id='contactSubmit'
                      className='rounded-md bg-primary px-3.5 py-2.5 text-center text-sm font-semibold text-light-text shadow-sm hover:text-dark-text hover:bg-primary-light focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                    >
                      Send message
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>

      <FooterWebsite />
    </div>
  );
}
