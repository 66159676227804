import React from "react";
import { Link } from "react-router-dom";
import valuesIconGreen from "../../Images/aboutUsBlackText.svg";
import HeaderMenu from "../../Components/HeaderMenu";
import FooterWebsite from "../../Components/FooterWebsite";
import dogwalk1 from "../../Images/about us page/dogwalk1.jpg";
import yoga3 from "../../Images/about us page/yoga3.jpg";
import hiking1 from "../../Images/about us page/hiking1.jpg";
import office2 from "../../Images/about us page/meeting1.jpg";
import coffeeShop2 from "../../Images/about us page/coffeeShop5.jpg";
import guyCooking1 from "../../Images/about us page/guyCooking1.jpg";
import bruce_website_logo from "../../Images/Bruce_website_logo.png";
import ReactPlayer from "react-player";

const values = [
  {
    name: "Inspire",
    description:
      "We want to inspire people to intentionally and proactively enhance their mental health through a simple process that they control.",
  },
  {
    name: "Create",
    description:
      "We believe in encouraging individuals to explore and create new pathways to enhance their mental health.",
  },
  {
    name: "Share",
    description:
      "We aim to provide a mechanism and share resources for people who want to prioritize their mental health in a dynamic world.",
  },
  {
    name: "Lead",
    description:
      "We aspire to continually grow our resources and provide leadership, innovation, and collaboration in the community of mental health professionals and organizations.",
  },
];

export default function AboutPage() {
  return (
    <div className='bg-background overflow-x-hidden'>
      {/* Header */}
      <HeaderMenu />
      <main className='isolate'>
        {/* Hero section */}
        <div className='relative isolate -z-10'>
          <div
            className='absolute left-0 top-0 -z-10 transform-gpu overflow-hidden blur-3xl sm:top-0'
            aria-hidden='true'
          >
            <div
              className='relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-primary-light to-primary opacity-30 sm:left-4 sm:w-[72.1875rem]'
              style={{
                clipPath:
                  "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              }}
            />
          </div>
          <div
            className='absolute left-0 bottom-0 -z-10 transform-gpu overflow-hidden blur-3xl sm:bottom-0'
            aria-hidden='true'
          >
            <div
              className='aspect-[1097/845] w-[68.5625rem] bg-gradient-to-r from-primary-light to-primary opacity-25'
              style={{
                clipPath:
                  "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              }}
            />
          </div>
          {/* <svg
            className="absolute inset-x-0 top-0 -z-10 h-[64rem] w-full stroke-dark-background [mask-image:radial-gradient(32rem_32rem_at_center,white,transparent)]"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84"
                width={200}
                height={200}
                x="50%"
                y={-1}
                patternUnits="userSpaceOnUse"
              >
                <path d="M.5 200V.5H200" fill="none" />
              </pattern>
            </defs>
            <svg x="50%" y={-1} className="overflow-visible fill-background">
              <path
                d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
                strokeWidth={0}
              />
            </svg>
            <rect
              width="100%"
              height="100%"
              strokeWidth={0}
              fill="url(#1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84)"
            />
          </svg> */}
          <div
            className='absolute left-1/2 right-0 top-0 -z-10 -ml-24 transform-gpu overflow-hidden blur-3xl lg:ml-24 xl:ml-48'
            aria-hidden='true'
          >
            <div
              className='aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30'
              style={{
                clipPath:
                  "polygon(63.1% 29.5%, 100% 17.1%, 76.6% 3%, 48.4% 0%, 44.6% 4.7%, 54.5% 25.3%, 59.8% 49%, 55.2% 57.8%, 44.4% 57.2%, 27.8% 47.9%, 35.1% 81.5%, 0% 97.7%, 39.2% 100%, 35.2% 81.4%, 97.2% 52.8%, 63.1% 29.5%)",
              }}
            />
          </div>
          <div className='overflow-hidden'>
            <div className='mx-auto max-w-7xl px-6 pb-32 pt-36 sm:pt-60 lg:px-8 lg:pt-32'>
              <div className='mx-auto max-w-2xl gap-x-14 lg:mx-0 lg:flex lg:max-w-none lg:items-center'>
                <div className='w-full max-w-xl lg:shrink-0 xl:max-w-2xl'>
                  <h1 className='text-4xl font-bold tracking-tight text-dark-text sm:text-6xl'>
                    We’re changing the way people think about their mental
                    health
                  </h1>
                  <p className='relative mt-6 text-lg leading-8 text-dark-text sm:max-w-md lg:max-w-none'>
                    We are passionate about helping everyone change the way they
                    think about their mental health. We provide a tool for
                    people to connect and combine different areas of health that
                    are positively related to mental health, and to
                    intentionally plan for enhancing their mental health.
                  </p>
                  <p className='relative mt-6 text-lg leading-8 text-dark-text sm:max-w-md lg:max-w-none'>
                    The{" "}
                    <span className='font-semibold text-primary'>
                      Self-Prescription
                    </span>{" "}
                    aspect of this activity allows you to combine and
                    simultaneously invest in multiple areas that positively
                    impact your mental health intentionally and proactively.
                  </p>
                </div>
                <div className='mt-14 flex justify-end gap-8 sm:-mt-44 sm:justify-start sm:pl-20 lg:mt-0 lg:pl-0'>
                  <div className='ml-auto w-44 flex-none space-y-8 pt-32 sm:ml-0 sm:pt-80 lg:order-last lg:pt-36 xl:order-none xl:pt-80'>
                    <div className='relative'>
                      <img
                        src={coffeeShop2}
                        alt=''
                        className='aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg'
                      />
                      <div className='pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10' />
                    </div>
                  </div>
                  <div className='mr-auto w-44 hidden sm:block flex-none space-y-8 sm:mr-0 sm:pt-52 lg:pt-36'>
                    <div className='relative'>
                      <img
                        src={dogwalk1}
                        alt=''
                        className='aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg'
                      />
                      <div className='pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10' />
                    </div>
                    <div className='relative'>
                      <img
                        src={yoga3}
                        alt=''
                        className='aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg'
                      />
                      <div className='pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10' />
                    </div>
                  </div>
                  <div className='w-44 hidden md:block flex-none space-y-8 pt-32 sm:pt-0'>
                    <div className='relative'>
                      <img
                        src={hiking1}
                        alt=''
                        className='aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg'
                      />
                      <div className='pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10' />
                    </div>
                    <div className='relative'>
                      <img
                        src={guyCooking1}
                        alt=''
                        className='aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg'
                      />
                      <div className='pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10' />
                    </div>
                    <div className='relative'>
                      <img
                        src={office2}
                        alt=''
                        className='aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg'
                      />
                      <div className='pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Content section */}
        <div className='mx-auto -mt-12 max-w-7xl px-6 sm:mt-0 lg:px-8 xl:-mt-8'>
          <div className='mx-auto max-w-2xl lg:mx-0 lg:max-w-none'>
            <h2 className='text-3xl font-bold tracking-tight text-dark-text sm:text-4xl'>
              Our mission
            </h2>
            <div className='mt-6 flex flex-col gap-x-8 gap-y-20 lg:flex-row'>
              <div className='lg:w-full lg:max-w-2xl lg:flex-auto'>
                <p className='text-lg leading-8 p-4 text-dark-text'>
                  To empower and inspire individuals to enhance their mental
                  health one connection at a time.
                </p>
              </div>
              <div className='lg:flex lg:flex-auto lg:justify-center'>
                <dl className='w-full space-y-8 sm:w-3/4'>
                  <img
                    src='https://images.unsplash.com/photo-1529156069898-49953e39b3ac?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2832&q=80'
                    alt=''
                    className=' w-full object-fill rounded-xl xl:rounded-3xl'
                  />
                  {/* {stats.map((stat) => (
                    <div
                      key={stat.label}
                      className="flex flex-col-reverse gap-y-4"
                    >
                      <dt className="text-base leading-7 text-dark-text">
                        {stat.label}
                      </dt>
                      <dd className="text-5xl font-semibold tracking-tight text-dark-text">
                        {stat.value}
                      </dd>
                    </div>
                  ))} */}
                </dl>
              </div>
            </div>
          </div>
        </div>

        {/* Image section */}
        {/* <div className="mt-32 sm:mt-40 xl:mx-auto xl:max-w-7xl xl:px-8">
          <img
            src="https://images.unsplash.com/photo-1529156069898-49953e39b3ac?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2832&q=80"
            alt=""
            className="aspect-[5/2] w-full object-cover xl:rounded-3xl"
          />
        </div> */}

        {/* Values section */}
        <div className='mx-auto mt-32 max-w-7xl px-6 sm:mt-40 lg:px-8'>
          <div className='mx-auto max-w-2xl lg:mx-0'>
            <h2 className='text-3xl font-bold tracking-tight text-dark-text sm:text-4xl'>
              Our values
            </h2>
          </div>
          <div className='grid grid-cols-1 sm:grid-cols-2'>
            <dl className='mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 text-base leading-7 sm:grid-cols-2 lg:mx-0 lg:max-w-1/2 lg:grid-cols-2'>
              {values.map((value) => (
                <div key={value.name} className=''>
                  <dt className='font-semibold text-xl text-primary'>
                    {value.name}
                  </dt>
                  <dd className='mt-1 text-lg text-dark-text'>
                    {value.description}
                  </dd>
                </div>
              ))}
            </dl>

            <img src={valuesIconGreen} alt='' className='m-auto w-full' />
          </div>
        </div>

        {/* Logo cloud */}
        <div className='relative isolate -z-10 mt-32 sm:mt-48'>
          <div
            className='absolute left-0 top-0 -z-10 transform-gpu overflow-hidden blur-3xl sm:top-0'
            aria-hidden='true'
          >
            <div
              className='relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-primary-light to-primary opacity-30 sm:left-4 sm:w-[72.1875rem]'
              style={{
                clipPath:
                  "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              }}
            />
          </div>
          <div
            className='absolute left-0 bottom-0 -z-10 transform-gpu overflow-hidden blur-3xl sm:bottom-0'
            aria-hidden='true'
          >
            <div
              className='aspect-[1097/845] w-[68.5625rem] bg-gradient-to-r from-primary-light to-primary opacity-25'
              style={{
                clipPath:
                  "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              }}
            />
          </div>
        </div>
        <div className='mx-auto mt-32 max-w-7xl px-6 sm:mt-40 lg:px-8'>
          <div className='mx-auto max-w-2xl lg:mx-0'>
            <h2 className='text-3xl font-bold tracking-tight text-dark-text sm:text-4xl'>
              About Bruce
            </h2>
          </div>
          <div className='grid grid-cols-1 sm:grid-cols-2'>
            <div className='m-4'>
              <p className='text-lg'>
                Dr. Bruce Pinel has extensive experience relating to
                Performance, Health, and Wellness from a mental health
                perspective that is based on his unique combination of being a
                Registered Clinical Counsellor and having a Ph.D. in Sport
                Psychology. His pragmatic and integrative approach make him an
                innovative and sought-after clinician for both individuals and
                organizations, and it is through his wealth of experience and
                desire to effect positive change that he envisioned and created
                the Mental Health Connections Self-Prescription tool.
              </p>
              <div className='grid grid-cols-2'>
                <Link
                  to='https://drbrucepinel.ca/'
                  target='_blank'
                  className='p-4 w-full'
                >
                  <img src={bruce_website_logo} alt='' className='w-full' />
                </Link>
              </div>
            </div>
            <ReactPlayer
              url='https://vimeo.com/929354731?share=copy'
              controls
              className='w-full row-start-1 sm:col-start-2 p-4'
            />
          </div>
        </div>
      </main>

      <FooterWebsite />
    </div>
  );
}
